import React, {Fragment, useRef, useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';


import {mostViewSort} from "../../utils/commonFunctions";
import { getPostsByType } from '../../store/actions/postAction';
import dayjs from 'dayjs';


const MostView = ({no_margin, title, dark}) => {
    const [mostView, setMostView] = useState([]);

    useEffect(() => {
        const getData = async()=>{
            let data = await getPostsByType("most_viewed");
            setMostView(data);
        }

        getData();
    }, [])
    
    const swiperRef = useRef();

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const params = {
        slidesPerView: 1,
        modules:[Grid],
        grid:{rows:6}
        
    };
    return (
        <div className={`widget tab_widgets ${no_margin ? '' : 'mb30'}`}>
            <h2 className="widget-title">{title ? title : 'Most View'}</h2>
            <div className="post_type2_carousel multipleRowCarousel nav_style1">
                {/*CAROUSEL START*/}
                <Swiper ref={swiperRef} {...params} >
                    {mostViewSort(mostView).map((item, i) => (
                        <SwiperSlide key={item.id}>
                            <div key={i} className="single_post2_carousel">
                            <div className="single_post widgets_small type8">
                                <div className="post_img">
                                    <div className="img_wrap">
                                        <img src={item.image} alt="thumb"/>
                                    </div>
                                    <span className="tranding">
										<FontAwesome name="bolt"/>
									</span>
                                </div>
                                <div className="single_post_text">
                                    <div className="meta2"><Link className='category' to="/">{item.category}</Link>
                                        <Link to="/">{dayjs(item?.date).format('MMM DD, YYYY')}</Link>
                                    </div>
                                    <h4><Link to={`/${item?.slug}`}>{item.title}</Link></h4>
                                </div>
                                <div className="type8_count">
                                    <h2>{item.id}</h2>
                                </div>
                            </div>
                            {i + 2 < mostView.length ? <Fragment>
                                <div className="space-15"/>
                                {dark ? <div className="border_white"/> : <div className="border_black"/>}
                                <div className="space-15"/>
                            </Fragment> : null}
                        </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="navBtns">
                    <div onClick={goPrev} className="navBtn prevtBtn"><FontAwesome name="angle-left"/></div>
                    <div onClick={goNext} className="navBtn nextBtn"><FontAwesome name="angle-right"/></div>
                </div>
                {/*CAROUSEL END*/}
            </div>
        </div>
    );
};

export default MostView;