import React, {useState, useRef} from 'react';
import {Link} from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";


import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';

// images
import sports2 from '../../doc/img/sports/sports2.jpg';
import sports3 from '../../doc/img/sports/sports3.jpg';
import sports4 from '../../doc/img/sports/sports4.jpg';
import sports5 from '../../doc/img/sports/sports5.jpg';
import sports6 from '../../doc/img/sports/sports6.jpg';
import blog_small1 from '../../doc/img/blog/blog_small1.jpg';
import blog_small2 from '../../doc/img/blog/blog_small2.jpg';
import blog_small3 from '../../doc/img/blog/blog_small3.jpg';
import blog_small4 from '../../doc/img/blog/blog_small4.jpg';
import blog_small5 from '../../doc/img/blog/blog_small5.jpg';

import {mostViewSort} from "../../utils/commonFunctions";
import dayjs from 'dayjs';



const SportsCarousel = ({no_margin, title, dark, sportsdata}) => {
    const swiperRef = useRef();

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
        
    };

    const params = {
        slidesPerView: 1,
        modules:[Grid],
        grid:{rows:6}
    };
    return (
        <div className="widget tab_widgets">
            <div className="post_type2_carousel multipleRowCarousel nav_style1">
                {/*CAROUSEL START*/}
                <Swiper ref={swiperRef} {...params}>
                    {sportsdata.map((item, i) => (
                       <SwiperSlide key={item?.slug}>
                       <div key={i} className="single_post2_carousel">
                            <div className="single_post widgets_small">
                                <div className="post_img">
                                    <div className="img_wrap">
                                        <Link to={`/${item?.slug}`}><img src={item.image} alt="thumb"/></Link>
                                    </div>
                                    <span className="tranding"><FontAwesome name="bolt"/></span>
                                </div>
                                <div className="single_post_text">
                                    <div className="meta2"><Link to="/">{item.category}</Link>
                                        <Link to="/">{dayjs(item.date).format('MMM DD, YYYY')}</Link>
                                    </div>
                                    <h4><Link to={`/${item?.slug}`}>{item.title}</Link></h4>
                                </div>
                            </div>
                            <div className="space-15"/>
                            {dark ? <div className="border_white"/> : <div className="border_black"/>}
                            <div className="space-15"/>
                        </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="navBtns">
                    <div onClick={goPrev} className="navBtn prevtBtn"><FontAwesome name="angle-left"/></div>
                    <div onClick={goNext} className="navBtn nextBtn"><FontAwesome name="angle-right"/></div>
                </div>
            </div>
        </div>
    );
};

export default SportsCarousel;