import React from 'react';
import {Link} from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";

const FollowUs = ({className='', title}) => {
    return (
        <div className={`follow_box widget mb30 ${className}`}>
            <h2 className="widget-title">{title}</h2>
            <div className="social_shares">
                <Link className="single_social social_facebook" to="https://facebook.com/supra" target='_blank'>
                    <span className="follow_icon"><FontAwesome name="facebook-f"/></span>
                    14,456 <span className="icon_text">Fans</span>
                </Link>
                <Link className="single_social social_twitter" to="https://twitter.com/supra">
                    <span className="follow_icon"><FontAwesome name="twitter"/></span>
                    24,456 <span className="icon_text">Followers</span>
                </Link>
                <Link className="single_social social_youtube" to="youtube.com/supra">
                    <span className="follow_icon"><FontAwesome name="youtube"/></span>
                    24,908 <span className="icon_text">Subscribers</span>
                </Link>
                <Link className="single_social social_instagram" to="instagram/supra">
                    <span className="follow_icon"><FontAwesome name="instagram"/></span>
                    32,232 <span className="icon_text">Followers</span>
                </Link>
                <Link className="single_social social_vimeo" to="#">
                    <span className="follow_icon"><FontAwesome name="vimeo"/></span>
                    31,609 <span className="icon_text">Followers</span>
                </Link>
                <Link className="single_social social_medium" to="#">
                    <span className="follow_icon"><FontAwesome name="medium"/></span>
                    30,232 <span className="icon_text">Followers</span>
                </Link>
            </div>
        </div>
    );
};

export default FollowUs;