import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import { getNextPost, getPreviousPost } from '../../store/actions/postAction';

const PostOnePagination = ({id, className }) => {

    const [previous, setPrevious] = useState();
    const [next, setNext] = useState();

    useEffect(() => {
        if(id){
            getPreviousPost(id).then(response=>setPrevious(response)).catch(err=>console.log(err))
            getNextPost(id).then(response=>setNext(response)).catch(err=>console.log(err))
        }
    }, [id])
    
    return (
        <div className="next_prev">
            <div className="row">
                <div className="col-lg-6 align-self-center">
                    <div className={`${className ? className : 'next_prv_single border_left3'}`}>
                        <p>PREVIOUS NEWS</p>
                        <h3><Link to={`/${previous?.slug}`}>{previous?.title}</Link></h3>
                    </div>
                </div>
                <div className="col-lg-6 align-self-center">
                    <div className={`${className ? className : 'next_prv_single border_left3'}`}>
                        <p>NEXT NEWS</p>
                        <h3><Link to={`/${next?.slug}`}>{next?.title}</Link></h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostOnePagination;