import React from 'react'
import { Button, Form, FormGroup, Label, Input, Container, Row, Col } from "reactstrap";
import { createPost } from '../../store/actions/postAction';


function CreatePost() {
    const handleSubmit = async (e)=>{
        e.preventDefault();
        let data = {
            title:e.target.title.value,
            body:e.target.body.value,
            category:e.target.category.value,
            image:e.target.image.value,
            date:e.target.date.value
        }
        const ref = await createPost(data);
        console.log(ref);
    }
  return (
    <Container>
        <Row>
            <Col>
    <Form onSubmit={handleSubmit}>
    <FormGroup>
      <Label for="exampleName">Title :</Label>
      <Input type="text" name="title" placeholder="Enter your name" />
    </FormGroup>
    <FormGroup>
      <Label for="exampleEmail">Body :</Label>
      <Input  type="textarea" name="body" placeholder="Enter your email" />
    </FormGroup>
    <FormGroup>
      <Label for="examplePassword">Image :</Label>
      <Input type="text" name="image" placeholder="Enter a password" />
    </FormGroup>
    <FormGroup>
      <Label for="examplePassword">Category :</Label>
      <Input type="text" name="category" placeholder="Enter a category" />
    </FormGroup>
    <FormGroup>
      <Label for="examplePassword">Date :</Label>
      <Input type="text" name="date" placeholder="Enter a date" />
    </FormGroup>
    <Button>Submit</Button>
  </Form>
  </Col>
  </Row>
  </Container>
  )
}

export default CreatePost