import React, {useRef,useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Swiper from 'react-id-swiper';
import FontAwesome from "../uiStyle/FontAwesome";
import 'swiper/css';
import 'swiper/css/autoplay';
import { getTrendingPosts } from '../../store/actions/postAction';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import dayjs from 'dayjs';
const TopBar = ({className, dark}) => {
const [trending, setTrending] = useState([]);
    
   useEffect(() => {
    const getPost = async ()=>{
        const posts = await getTrendingPosts();
        setTrending(posts);
    }
    getPost();
    
    

   }, [])
    const swiperRef = useRef(null);

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            const swiper = swiperRef.current.swiper;
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            const swiper = swiperRef.current.swiper;
            swiper.slidePrev();
        }
    };
    const params = {
        
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
    };

  

  
    return (
        <div className={`topbar ${className ? className : ''}`} id="top">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 align-self-center">
                        <div className={`trancarousel_area ${dark ? 'white' : ''}`}>
                            <p className='trand'>TRENDING NOW</p>
                            <div className="nav_style1">
                                <Swiper autoplay={{delay: 2500,disableOnInteraction: true}} modules={[Autoplay]} ref={swiperRef}  className="trancarousel" {...params}>
                                    {trending && trending.map(item=>

                                        <div className="trancarousel_item" key={item?.slug}>
                                        <p><Link to={`/${item?.slug}`}>{item.title}</Link>
                                        </p>
                                    </div>
                                    )}
                                </Swiper>
                                <div className="navBtns">
                                    <button className="navBtn prevBtn" onClick={goPrev}><FontAwesome name="angle-left"/>
                                    </button>
                                    <button className="navBtn nextBtn" onClick={goNext}><FontAwesome
                                        name="angle-right"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 align-self-center">
                        <div className="top_date_social text-right">
                            <div className={`paper_date ${dark ? 'white' : ''}`}>
                                <p>{dayjs().format('dddd, MMMM DD, YYYY')}</p>
                            </div>
                            <div className={`social1 ${dark ? 'white' : ''}`}>
                                <ul className="inline">
                                    <li><Link to="#"><FontAwesome name="twitter"/></Link></li>
                                    <li><Link to="#"><FontAwesome name="facebook-f"/></Link></li>
                                    <li><Link to="#"><FontAwesome name="youtube-play"/></Link></li>
                                    <li><Link to="#"><FontAwesome name="instagram"/></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBar;