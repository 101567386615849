import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import sportsbig1 from "../../doc/img/sports/sportsbig1.jpg";
import SportsCarousel from "../SportsCarousel";
import FontAwesome from "../uiStyle/FontAwesome";
import { getPostsByCategory } from '../../store/actions/postAction';
import dayjs from 'dayjs';
import Markdown from 'react-markdown';

const SportsNews = ({dark}) => {
    const [sports, setSports] = useState([]);
    const [featured, setFeatured] = useState();
    useEffect(() => {
        let getData = async()=>{
            let data = await getPostsByCategory("sports");
            setSports(data);
            setFeatured(data[data.length-1])
        }

        getData();
     
    }, [])
    
    return (
        <div className="row">
            <div className="col-12">
                <div className="sports">
                    <div className="row">
                        <div className="col-12">
                            <div className="heading">
                                <h2 className="widget-title">Sports News</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="single_post post_type3 mb30">
                                <div className="post_img">
                                    <Link to="/">
                                        <img src={featured?.image} alt="sportsbig1"/>
                                    </Link> <span className="tranding">
													<FontAwesome name="bolt"/>
												</span>
                                </div>
                                <div className="single_post_text">
                                    <div className="meta3"><Link to="/">SPORTS</Link>
                                        <Link to="/">{dayjs(featured?.date).format('MMM DD, YYYY')}</Link>
                                    </div>
                                    <h4><Link to={featured?.slug}>{featured?.title}</Link></h4>
                                    <div className="space-10"/>
                                    <p className="post-p">{featured?.description}</p>
                                    <div className="space-20"/>
                                    <Link to={featured?.slug} className="readmore">Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="sports_carousel nav_style1">
                                <SportsCarousel sportsdata={sports} dark={dark ? dark : false}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SportsNews;