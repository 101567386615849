import React from 'react';
import FontAwesome from "../uiStyle/FontAwesome";

const twitts = [
    {
        title: 'Introduction Offer, Save 20% on Oneflow Accounting Software during our year-end Sale, Purchase a new subscription for your business',
        linkText: '@oneflow #TECHNOLOGY https://oneflow.pro/signup',
        date: 'March 26, 2024'
    },
    {
        title: ' CEO: I am proud to announce our commitment to becoming the foremost authority in delivering timely news updates and unbiased product reviews, fostering an engaged community and empowering consumers through transparent and entertaining content. ',
        linkText: '@supra #TECHNOLOGY https://supra.com.np/news/ceo',
        date: 'March 26, 2020'
    },
];

const TwitterFeed = () => {
    return (
        <div className="twitter_feeds">
            <h3 className="widget-title2">Twitter feed</h3>
            {twitts.map((item, i) => (
                <div key={i} className="single_twitter_feed border_white_bottom">
                    <div className="twitter_feed_icon"><FontAwesome name="twitter"/>
                    </div>
                    <h6>{item.title}… <span>{item.linkText}</span></h6>
                    <p>{item.date}</p>
                </div>
            ))}
        </div>
    );
};

export default TwitterFeed;