import React, { useState } from 'react'
import { Routes as RRoutes, Route } from 'react-router-dom'
import MyAccount from './MyAccount/MyAccount'
import PrivateRoute from './_PrivateRoute/PrivateRoute'
import Routes from './__Routes'
import { onAuthStateChanged } from 'firebase/auth'
import { analytics, auth } from '../utils/firebase'
import { getAnalytics, logEvent } from "firebase/analytics";



function AuthRoutes() {
  


  const [currentUser, setCurrentUser] = useState();
  onAuthStateChanged(auth,function(user){
    if(user){
      setCurrentUser(user);
    }
  })
  return (
    <RRoutes>
        {currentUser && <Route exact path='/myaccount' element={<MyAccount user={currentUser}/>}/>}
        {currentUser && <Route
                exact
                path="/edit/:id"
                parentClass="theme-1"
                element={<MyAccount/>}/>}
        <Route path="/*" element={<PrivateRoute>
                <Routes/>
            </PrivateRoute>}></Route>
           
            
    </RRoutes>
  )
}

export default AuthRoutes