import dayjs from 'dayjs'
import React, { useCallback } from 'react'

const useFormat = () => {
    
  const formatDate = useCallback((date, format='MMM DD, YYYY HH:mm:ss')=>{
    return dayjs(date).format(format);
  },[])

  return {formatDate:formatDate}
}

export default useFormat