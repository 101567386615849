import React from 'react';
import {Route, Routes as RRoutes} from 'react-router-dom';

import HomePage from "../HomePage";
import HomePageTwo from "../HomePageTwo";
import BusinessPage from "../BusinessPage";
import EntertainmentPage from "../EntertainmentPage";
import FeaturePage from "../FeaturePage";
import SportsPage from "../SportsPage";
import TrendingPage from "../TrendingPage";
import AboutUsPage from "../AboutUsPage";
import ArchivePage from "../ArchivePage";
import ContactUsPage from "../ContactUsPage";
import NotFoundPage from "../NotFoundPage";
import PostOnePage from "../PostOnePage";
import PostTwoPage from "../PostTwoPage";
import PostThreePage from "../PostThreePage";
import VideoPostOnePage from "../VideoPostOnePage";
import VideoPostTwoPage from "../VideoPostTwoPage";
import VideoPostThreePage from "../VideoPostThreePage";
import AudioPostOnePage from "../AudioPostOnePage";
import AudioPostTwoPage from "../AudioPostTwoPage";
import AudioPostThreePage from "../AudioPostThreePage";
import PostOneLeftSidebarPage from "../PostOneLeftSidebarPage";
import BusinessTwoPage from "../BusinessTwoPage";
import EntertainmentTwoPage from "../EntertainmentTwoPage";
import FeatureTwoPage from "../FeatureTwoPage";
import SportsTwoPage from "../SportsTwoPage";
import TrendingTwoPage from "../TrendingTwoPage";
import AboutUsPageTwo from "../AboutUsTwoPage";
import ArchiveTwoPage from "../ArchiveTwoPage";
import PostOneHTwoPage from "../PostOneHTwoPage";
import PostTwoHTwoPage from "../PostTwoHTwoPage";
import PostThreeHTwoPage from "../PostThreeHTwoPage";
import VideoPostOneHTwoPage from "../VideoPostOneHTwoPage";
import VideoPostTwoHTwoPage from "../VideoPostTwoHTwoPage";
import VideoPostThreeHTwoPage from "../VideoPostThreeHTwoPage";
import AudioPostOneHTwoPage from "../AudioPostOneHTwoPage";
import AudioPostTwoHTwoPage from "../AudioPostTwoHTwoPage";
import AudioPostThreeHTwoPage from "../AudioPostThreeHTwoPage";
import PostOneHTwoLeftSidebarPage from "../PostOneHTwoLeftSidebarPage";
import HomePageThree from "../HomePageThree";
import BusinessThreePage from "../BusinessThreePage";
import EntertainmentThreePage from "../EntertainmentThreePage";
import FeatureThreePage from "../FeatureThreePage";
import SportsThreePage from "../SportsThreePage";
import TrendingThreePage from "../TrendingThreePage";
import AboutUsThreePage from "../AboutUsThreePage";
import ArchiveThreePage from "../ArchiveThreePage";
import ContactUsThreePage from "../ContactUsThreePage";
import NotFoundThreePage from "../NotFoundThreePage";
import PostOneHThreePage from "../PostOneHThreePage";
import PostTwoHThreePage from "../PostTwoHThreePage";
import PostThreeHThreePage from "../PostThreeHThreePage";
import VideoPostOneHThreePage from "../VideoPostOneHThreePage";
import VideoPostTwoHThreePage from "../VideoPostTwoHThreePage";
import VideoPostThreeHThreePage from "../VideoPostThreeHThreePage";
import AudioPostOneHThreePage from "../AudioPostOneHThreePage";
import AudioPostTwoHThreePage from "../AudioPostTwoHThreePage";
import AudioPostThreeHThreePage from "../AudioPostThreeHThreePage";
import PostOneHThreeLeftSidebarPage from "../PostOneHThreeLeftSidebarPage";
import HomeDarkPage from "../HomeDarkPage";
import BusinessDarkPage from "../BusinessDarkPage";
import EntertainmentDarkPage from "../EntertainmentDarkPage";
import FeatureDarkPage from "../FeatureDarkPage";
import SportsDarkPage from "../SportsDarkPage";
import TrendingDarkPage from "../TrendingDarkPage";
import AboutUsDarkPage from "../AboutUsDarkPage";
import ArchiveDarkPage from "../ArchiveDarkPage";
import ContactUsDarkPage from "../ContactUsDarkPage";
import NotFoundDarkPage from "../NotFoundDarkPage";
import PostOneDarkPage from "../PostOneDarkPage";
import PostTwoDarkPage from "../PostTwoDarkPage";
import PostThreeDarkPage from "../PostThreeDarkPage";
import VideoPostOneDarkPage from "../VideoPostOneDarkPage";
import VideoPostTwoDarkPage from "../VideoPostTwoDarkPage";
import VideoPostThreeDarkPage from "../VideoPostThreeDarkPage";
import AudioPostOneDarkPage from "../AudioPostOneDarkPage";
import AudioPostTwoDarkPage from "../AudioPostTwoDarkPage";
import AudioPostThreeDarkPage from "../AudioPostThreeDarkPage";
import PostOneLeftSidebarDarkPage from "../PostOneLeftSidebarDarkPage";
import CreatePost from '../../components/New Post/CreatePost';
import MyAccount from '../MyAccount/MyAccount';
// import PublicRoute from '../_PublicRoute';

const Routes = () => {
    return (
        <RRoutes>
            {/*home one routes*/}
            <Route
                exact
                path="/"
                parentClass="theme-1"
                element={<HomePage/>}/>
            <Route
                exact
                path="/business"
                parentClass="theme-1"
                element={<BusinessPage/>}/>
            <Route
                exact
                path="/entertainment"
                parentClass="theme-1"
                element={<EntertainmentPage/>}/>
            <Route
                exact
                path="/features"
                parentClass="theme-1"
                element={<FeaturePage/>}/>
            <Route
                exact
                path="/trending"
                parentClass="theme-1"
                element={<TrendingPage/>}/>
            <Route
                exact
                path="/sports"
                parentClass="theme-1"
                element={<SportsPage/>}/>
            <Route
                exact
                path="/about"
                parentClass="theme-1"
                element={<AboutUsPage/>}/>

            <Route
                exact
                path="/archive"
                parentClass="theme-1"
                element={<ArchivePage/>}/>
            <Route
                exact
                path="/contact"
                parentClass="theme-1"
                element={<ContactUsPage/>}/>
            <Route
                exact
                path="/404"
                parentClass="theme-1"
                element={<NotFoundPage/>}/>
            <Route
                exact
                path="/post1"
                parentClass="theme-1"
                element={<PostOnePage/>}/>
            <Route
                exact
                path="/post2"
                parentClass="theme-1"
                element={<PostTwoPage/>}/>
            <Route
                exact
                path="/post3"
                parentClass="theme-1"
                element={<PostThreePage/>}/>
            <Route
                exact
                path="/video_post1"
                parentClass="theme-1"
                element={<VideoPostOnePage/>}/>
            <Route
                exact
                path="/video_post2"
                parentClass="theme-1"
                element={<VideoPostTwoPage/>}/>
            <Route
                exact
                path="/video_post3"
                parentClass="theme-1"
                element={<VideoPostThreePage/>}/>
            <Route
                exact
                path="/audio_post1"
                parentClass="theme-1"
                element={<AudioPostOnePage/>}/>
            <Route
                exact
                path="/audio_post2"
                parentClass="theme-1"
                element={<AudioPostTwoPage/>}/>
            <Route
                exact
                path="/audio_post3"
                parentClass="theme-1"
                element={<AudioPostThreePage/>}/>
            <Route
                exact
                path="/left_post2"
                parentClass="theme-1"
                element={<PostOneLeftSidebarPage/>}/>

            {/*home two routes*/}
            <Route
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two"
                element={<HomePageTwo/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two/business"
                element={<BusinessTwoPage/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two/entertainment"
                element={<EntertainmentTwoPage/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two/features"
                element={<FeatureTwoPage/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two/sports"
                element={<SportsTwoPage/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two/trending"
                element={<TrendingTwoPage/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two/about"
                element={<AboutUsPageTwo/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two/archive"
                element={<ArchiveTwoPage/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two/contact"
                element={<ContactUsPage/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/post1"
                element={<PostOneHTwoPage/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/post2"
                element={<PostTwoHTwoPage/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/post3"
                element={<PostThreeHTwoPage/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/video_post1"
                element={<VideoPostOneHTwoPage/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/video_post2"
                element={<VideoPostTwoHTwoPage/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/video_post3"
                element={<VideoPostThreeHTwoPage/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/audio_post1"
                element={<AudioPostOneHTwoPage/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/audio_post2"
                element={<AudioPostTwoHTwoPage/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/audio_post3"
                element={<AudioPostThreeHTwoPage/>}/>
            <Route
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/left_post2"
                element={<PostOneHTwoLeftSidebarPage/>}/>

            {/*home page three*/}
            <Route
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three"
                element={<HomePageThree/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/business"
                element={<BusinessThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/entertainment"
                element={<EntertainmentThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/features"
                element={<FeatureThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/sports"
                element={<SportsThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/trending"
                element={<TrendingThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/about"
                element={<AboutUsThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/archive"
                element={<ArchiveThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/contact"
                element={<ContactUsThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/404"
                element={<NotFoundThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/post1"
                element={<PostOneHThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/post2"
                element={<PostTwoHThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4 theme3_bg"
                path="/home-three/post3"
                element={<PostThreeHThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/video_post1"
                element={<VideoPostOneHThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/video_post2"
                element={<VideoPostTwoHThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/video_post3"
                element={<VideoPostThreeHThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/audio_post1"
                element={<AudioPostOneHThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/audio_post2"
                element={<AudioPostTwoHThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/audio_post3"
                element={<AudioPostThreeHThreePage/>}/>
            <Route
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/left_post2"
                element={<PostOneHThreeLeftSidebarPage/>}/>

            {/*home dark version*/}
            <Route
                exact
                home_style={4}
                path="/dark"
                parentClass="dark-theme primay_bg"
                element={<HomeDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/business"
                parentClass="dark-theme primay_bg"
                element={<BusinessDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/entertainment"
                parentClass="dark-theme primay_bg"
                element={<EntertainmentDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/features"
                parentClass="dark-theme primay_bg"
                element={<FeatureDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/sports"
                parentClass="dark-theme primay_bg"
                element={<SportsDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/trending"
                parentClass="dark-theme primay_bg"
                element={<TrendingDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/about"
                parentClass="dark-theme primay_bg"
                element={<AboutUsDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/archive"
                parentClass="dark-theme primay_bg"
                element={<ArchiveDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/contact"
                parentClass="dark-theme primay_bg"
                element={<ContactUsDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/404"
                parentClass="dark-theme primay_bg"
                element={<NotFoundDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/post1"
                parentClass="dark-theme primay_bg"
                element={<PostOneDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/post2"
                parentClass="dark-theme primay_bg"
                element={<PostTwoDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/post3"
                parentClass="dark-theme primay_bg"
                element={<PostThreeDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/video_post1"
                parentClass="dark-theme primay_bg"
                element={<VideoPostOneDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/video_post2"
                parentClass="dark-theme primay_bg"
                element={<VideoPostTwoDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/video_post3"
                parentClass="dark-theme primay_bg"
                element={<VideoPostThreeDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/audio_post1"
                parentClass="dark-theme primay_bg"
                element={<AudioPostOneDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/audio_post2"
                parentClass="dark-theme primay_bg"
                element={<AudioPostTwoDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/audio_post3"
                parentClass="dark-theme primay_bg"
                element={<AudioPostThreeDarkPage/>}/>
            <Route
                exact
                home_style={4}
                path="/dark/left_post2"
                parentClass="dark-theme primay_bg"
                element={<PostOneLeftSidebarDarkPage/>}/>

            <Route  path="/create" element={<CreatePost />} />

            <Route
                path="/:slug"
                parentClass="theme-1"
                element={<PostOnePage/>}/>


            <Route exact element={<NotFoundPage/>}/>
        </RRoutes>
    );
};
export default Routes