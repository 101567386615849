import React, {Fragment, useEffect, useState} from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import FontAwesome from "../../components/uiStyle/FontAwesome";
import {Link, useNavigate, useParams} from "react-router-dom";
import WidgetTab from "../../components/WidgetTab";
import WidgetTrendingNews from "../../components/WidgetTrendingNews";
import NewsLetter from "../../components/NewsLetter";
import MostShareWidget from "../../components/MostShareWidget";
import FollowUs from "../../components/FollowUs";
import BannerSection from "../../components/BannerSection";
import PostOnePagination from "../../components/PostOnePagination";

// images
import banner2 from "../../doc/img/bg/ads.png";
import OurBlogSection from "../../components/OurBlogSection";
import BlogComment from "../../components/BlogComment";
import { getPostBySlug, onDeletePost } from '../../store/actions/postAction';
import dayjs from 'dayjs';
import Markdown from 'react-markdown';
import { Modal, message } from 'antd';
import useModal from 'antd/es/modal/useModal';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../utils/firebase';
import useFormat from '../../utils/useFormat';




const PostOnePage = () => {
    const {formatDate} = useFormat();
    const {slug} = useParams();
    const [modal, container] = useModal();
    const [currentUser, setCurrentUser] = useState();
    const [selectedPost, setSelectedPost] = useState();

  
    const navigate = useNavigate();

 useEffect(() => {

    getPostBySlug(slug).then(response=>{
        setSelectedPost(response)
    })
 }, [slug])

 useEffect(() => {
    onAuthStateChanged(auth,function(user){
        setCurrentUser(user);
    })
 }, [])
 
 

    const deletePost = (id)=>{
        onDeletePost(id).then(response=>{
            message.error("Post deleted successfully !");
            navigate("/");
        }).catch(err=>{
            console.log(err);
        })
    }

    const onDelete = (id)=>{
        modal.confirm({title:"Delete Post ! Are you sure?", content:"This action is irreversible ! Please proceed with caution !", onOk:()=>deletePost(id)});
    }
    
    return (
        <Fragment>
            <div className="archives post post1">
                <BreadCrumb className="shadow5 padding-top-30" title="Post/23232"/>
                <span className="space-30"/>
                <div className="container">
                <div className="row">
                {selectedPost?<div className="col-md-6 col-lg-8">
                <div className="row">
                                <div className="col-6 align-self-center">
                                    <div className="page_category">
                                        <h4>{selectedPost?.category.toUpperCase()}</h4>
                                    </div>
                                </div>
                                <div className="col-6 text-right">
                                    <div className="page_comments">
                                        <ul className="inline">
                                        
                                           { currentUser &&
                                            <>
                                            <li><Link to={`/edit/${selectedPost?.id}`} style={{cursor:'pointer'}} ><FontAwesome name="edit"/>Edit</Link></li>
                                            <li><span style={{cursor:'pointer'}} onClick={()=>onDelete(selectedPost?.id)}><FontAwesome name="trash"/>Delete</span></li>
                                            </>
                                            }
                                            <li><FontAwesome name="comment"/>0</li>
                                            <li><FontAwesome name="fire"/>0</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="space-30"/>
                            <div className="single_post_heading">
                                <h1>{selectedPost?.title}</h1>
                                <div className="space-10"/>
                                <p>{selectedPost?.description}</p>
                            </div>
                            <div className="space-40"/>
                            <img height={524} width="100%" src={selectedPost?.image} alt="thumb"/>
                            <div className="space-20"/>
                            <div className="row">
                                <div className="col-lg-6 align-self-center">
                                    <div className="author">
                                        <div className="author_img">
                                            <div className="author_img_wrap">
                                                <img src={'https://img.freepik.com/free-photo/androgynous-avatar-non-binary-queer-person_23-2151100226.jpg?t=st=1709581589~exp=1709585189~hmac=216562412269d4f0bad4a4c6e54692f4f68f416467f4f98a405e054e30a1b5fb&w=826'} alt="author2"/>
                                            </div>
                                        </div>
                                        <Link to="/">{selectedPost?.author}</Link>
                                        <ul>
                                            <li><Link to="/">{formatDate(selectedPost?.date)}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                               
                                <div className="col-lg-6 align-self-center">
                                    <div className="author_social inline text-right">
                                        <ul>
                                            <li><Link to="/"><FontAwesome name="instagram"/></Link></li>
                                            <li><Link to="/"><FontAwesome name="facebook-f"/></Link></li>
                                            <li><Link to="/"><FontAwesome name="youtube"/></Link></li>
                                            <li><Link to="/"><FontAwesome name="instagram"/></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="space-20"/>
                            <Markdown>{selectedPost?.body}</Markdown>
                            <div className="space-40"/>
                            <div className="tags">
                                <ul className="inline">
                                    <li className="tag_list"><FontAwesome name="tag"/> tags</li>
                                    {selectedPost?.tags?.map((tag)=><li><Link to="/">{tag.toUpperCase()}</Link></li>)}
                                    
                                </ul>
                            </div>
                            <div className="space-40"/>
                            <div className="border_black"/>
                            <div className="space-40"/>
                            <PostOnePagination id={selectedPost?.id}/>
                        </div>:<div className="col-md-6 col-lg-8"><h1>Post Not Found !</h1></div>}
                        <div className="col-md-6 col-lg-4">
                            <WidgetTab/>
                            <FollowUs title="Follow Us"/>
                            <WidgetTrendingNews/>
                            <div className="banner2 mb30">
                                <Link to="/">
                                    <img src={banner2} alt="thumb"/>
                                </Link>
                            </div>
                            <MostShareWidget title="Most Share"/>
                            <NewsLetter/>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="space-60"/>
            <OurBlogSection/>
            <div className="space-60"/>
            <BlogComment/>
            <div className="space-100"/>
            <BannerSection/>
            {container}
        </Fragment>
    )
};

export default PostOnePage;