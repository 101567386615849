
import {  collection, getDocs, addDoc, query, where } from 'firebase/firestore/lite';
import { db } from "../../utils/firebase";
const tagsCol = collection(db, 'tags');

// Get a list of cities from your database
export async function getAllTags() {
  
  const postSnapshot = await getDocs(tagsCol);
  const postList = postSnapshot.docs.map(doc => doc.data());
  return postList;
}

export async function getAllCategories() {
  
  const postSnapshot = await getDocs(collection(db, 'categories'));
  const postList = postSnapshot.docs.map(doc => doc.data());
  return postList;
}

