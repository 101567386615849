import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';


// images
import {mostViewSort} from "../../utils/commonFunctions";
import { getPostsByTag } from '../../store/actions/postAction';



const MostShareWidget = ({no_margin,title, dark}) => {
    const [mostView, setMostView] = useState([]);
    useEffect(() => {
        const getData = async ()=>{
            let data = await getPostsByTag("most_viewed");
            setMostView(data);
        }
        getData();
    }, [])
    
    const swiperRef = useRef();

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const params = {
        slidesPerView: 1,
        modules:[Grid],
        grid:{rows:6}
    };
    return (
        <div className="widget tab_widgets mb30">
            <h2 className="widget-title">{title ? title : 'Most View'}</h2>
            <div className="post_type2_carousel multipleRowCarousel nav_style1">
                {/*CAROUSEL START*/}
                <Swiper ref={swiperRef} {...params}>
                    {mostViewSort(mostView).map((item, i) => (
                     <SwiperSlide>
                     <div key={i} className="carousel_items">
                            <div className="single_post widgets_small widgets_type4">
                                <div className="post_img number">
                                    <h2>{item.id}</h2>
                                </div>
                                <div className="single_post_text">
                                    <div className="meta2"><Link to="#">{item.category}</Link>
                                        <Link to="#">{item.date}</Link>
                                    </div>
                                    <h4><Link to="/post1">{item?.title}</Link></h4>
                                    <ul className="inline socail_share">
                                        <li><Link to="#"><FontAwesome name="twitter"/>2.2K</Link></li>
                                        <li><Link to="#"><FontAwesome name="facebook-f"/>2.2K</Link></li>
                                    </ul>
                                    <div className="space-15"/>
                                    {dark ? <div className="border_white"/> : <div className="border_black"/>}
                                </div>
                            </div>
                            <div className="space-15"/>
                        </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="navBtns">
                    <div onClick={goPrev} className="navBtn prevtBtn"><FontAwesome name="angle-left"/></div>
                    <div onClick={goNext} className="navBtn nextBtn"><FontAwesome name="angle-right"/></div>
                </div>
                {/*CAROUSEL END*/}
            </div>
        </div>
    );
};

export default MostShareWidget;