import React, {Fragment, useEffect, useState} from 'react';
import {TabContent, TabPane, Nav, NavItem, Fade} from 'reactstrap';
import classnames from 'classnames';
import {Link} from "react-router-dom";

import { getAllCategories } from '../../store/actions/tagAction';
import { getPostsByCategory } from '../../store/actions/postAction';




const WidgetTabPane = ({arr, a_id, id, dark}) => {
    
    return (
        <Fade in={id === a_id}>
            <div className="widget tab_widgets">
                {arr.map((item, i) => (
                    <Fragment key={i}>
                        <div className="single_post widgets_small">
                            <div className="post_img">
                                <div className="img_wrap">
                                    <Link to="/">
                                        <img src={item.image} alt="thumb"/>
                                    </Link>
                                </div>
                            </div>
                            <div className="single_post_text">
                                <div className="meta2 meta_separator1"><Link to="#">{item.category}</Link>
                                    <Link to={`/${item?.slug}`}>{item.date}</Link>
                                </div>
                                <h4><Link to={`/${item?.slug}`}>{item.title}</Link></h4>
                            </div>
                        </div>
                        <div className="space-15"/>
                        {dark ? <div className="border_white"/> : <div className="border_black"/>}
                        <div className="space-15"/>
                    </Fragment>
                ))}
            </div>
        </Fade>
    )
};

const WidgetTab = ({className, dark}) => {

    const [activeTab, setActiveTab] = useState('technology');
    const [data, setData] = useState([]);
    const [categories, setCategories] = useState();

    useEffect(() => {
        getAllCategories().then(response=>{
            setCategories(response);
        }).catch(err=>{
            console.log(err);
        })

        getData(activeTab);
    }, [])

    const getData = (category)=>{
        getPostsByCategory(category).then((response)=>{
            setData(response);
        })
    }

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
        getData(tab);
    };


    return (
        <div className={`widget_tab md-mt-30 ${className}`}>
            <Nav tabs>
                {categories?.map((item)=><NavItem style={{marginBlock:5}} key={item.value}>
                    <Link className={classnames({active: activeTab === item.value})}
                        onClick={() => {
                            toggle(item.value);
                        }}
                    >
                        {item?.label}
                    </Link>
                </NavItem>)}
            </Nav>
            <TabContent activeTab={activeTab}>
            {categories?.map((item)=><TabPane key={item.value} tabId={item.value}><WidgetTabPane dark={dark} a_id={activeTab} id={item.value} arr={data}/></TabPane>)}
            </TabContent>
        </div>
    );
};

export default WidgetTab;