import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import useFormat from '../../utils/useFormat';

const EntertainmentNews = ({entertainments}) => {
    const {formatDate} = useFormat();
    return (
        <Fragment>
            {entertainments.map((item, i) => (
                <div key={i} className="col-lg-6">
                    <div className="single_post post_type3 mb30">
                        <div className="post_img">
                            <div className="img_wrap">
                                <Link to={`/${item?.slug}`}>
                                    <img width="100%" height={200} src={item.image} alt="thumb"/>
                                </Link>
                            </div>
                        </div>
                        <div className="single_post_text">
                            <div className="meta3"><Link to="/">{item?.category.toUpperCase()}</Link>
                                <Link to="/">{formatDate(item?.date)}</Link>
                            </div>
                            <h4><Link to={`/${item?.slug}`}>{item?.title}</Link></h4>
                            <div className="space-10"/>
                            <p className="post-p">{item?.description}</p>
                        </div>
                    </div>
                </div>
            ))}
        </Fragment>
    );
};

export default EntertainmentNews;