// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore/lite';
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDlNtyqXY1xJ_nRByBRlhKJdQppSiqnMZ8",
  authDomain: "supra-blog-6562f.firebaseapp.com",
  projectId: "supra-blog-6562f",
  storageBucket: "supra-blog-6562f.appspot.com",
  messagingSenderId: "1034286842967",
  appId: "1:1034286842967:web:a17cdfdad076ade114cf12",
  measurementId: "G-S5DYV6JWGP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const analytics = getAnalytics(app);