import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import {mostViewSort} from "../../utils/commonFunctions";

// images
import popularsm1 from '../../doc/img/popular/popularsm1.jpg';
import popularsm2 from '../../doc/img/popular/popularsm2.jpg';
import popularsm3 from '../../doc/img/popular/popularsm3.jpg';
import popularsm4 from '../../doc/img/popular/popularsm4.jpg';
import popularsm5 from '../../doc/img/popular/popularsm5.jpg';

import './style.scss';
import { getPostsByType } from '../../store/actions/postAction';

const populerPOsts = [
    {
        image: popularsm1,
        category: 'TECHNOLOGY',
        title: 'The property complete with a 30 seat screen room.',
    },
    {
        image: popularsm2,
        category: 'TECHNOLOGY',
        title: 'Cheap smartphone sensor could help you old.',
    },
    {
        image: popularsm3,
        category: 'TECHNOLOGY',
        title: 'Harbour amid a Slowen the down in singer city',
    },
    {
        image: popularsm4,
        category: 'TECHNOLOGY',
        title: 'The secret to moving this from sphinx screening',
    },
    {
        image: popularsm5,
        category: 'TECHNOLOGY',
        title: 'Harbour amid a Slowen the down in singer city',
    },
    {
        image: popularsm1,
        category: 'TECHNOLOGY',
        title: 'The property complete with a 30 seat screen room.',
    },
    {
        image: popularsm2,
        category: 'TECHNOLOGY',
        title: 'Cheap smartphone sensor could help you old.',
    },
    {
        image: popularsm3,
        category: 'TECHNOLOGY',
        title: 'Harbour amid a Slowen the down in singer city',
    },
    {
        image: popularsm4,
        category: 'TECHNOLOGY',
        title: 'The secret to moving this from sphinx screening',
    },
    {
        image: popularsm5,
        category: 'TECHNOLOGY',
        title: 'Harbour amid a Slowen the down in singer city',
    },
];



const PopularPosts = ({no_margin, title,}) => {

    const [popular, setPopular] = useState([]);
    

    useEffect(() => {
     const getPopular = async()=>{
        let posts = await getPostsByType('popular');
        setPopular(posts);
     }

     getPopular();
    }, [])


    const swiperRef = useRef();

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const params = {
        slidesPerView: 1,
        modules:[Grid],
        grid:{rows:6}
    };
    return (
        <div className="popular_carousel_area mb30 md-mt-30">
            <h2 className="widget-title">Popular Posts</h2>
            <div className="popular_carousel pt-15 multipleRowCarousel nav_style1">
                {/*CAROUSEL START*/}
                <Swiper ref={swiperRef} {...params}>
                    {popular.map((item, i) => (
                        
                      <SwiperSlide key={item?.slug}>
                      <div key={i} className="single_post type10 widgets_small mb15">
                            <div className="post_img">
                                <div className="img_wrap">
                                    <Link to={`/${item?.slug}`}>
                                        <img src={item.image} alt="thubm"/>
                                    </Link>
                                </div>
                                <span className="tranding tranding_border">{item.id}</span>
                            </div>
                            <div className="single_post_text">
                                <h4><Link to={`/${item?.slug}`}>{item.title}</Link></h4>
                                <div className="meta4"><Link to="/">{item.category}</Link>
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="navBtns">
                    <div onClick={goPrev} className="navBtn prevtBtn"><FontAwesome name="angle-left"/></div>
                    <div onClick={goNext} className="navBtn nextBtn"><FontAwesome name="angle-right"/></div>
                </div>
                {/*CAROUSEL END*/}
            </div>
        </div>
    );
};

export default PopularPosts;