import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import {Provider} from 'react-redux'
import App from './containers/App/App';
import {BrowserRouter} from 'react-router-dom'
import store from './store'
import * as serviceWorker from './serviceWorker';

import 'react-toastify/dist/ReactToastify.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'swiper/css'
import 'react-modal-video/scss/modal-video.scss';
import './doc/scss/main.min.css';
import ReactGA from "react-ga4";
import reportWebVitals from './reportWebVitals';


const root = createRoot(document.getElementById("root"));

ReactGA.initialize("G-7BVSBYN284");



const SendAnalytics = ()=> {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });
  }
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
reportWebVitals(SendAnalytics);
