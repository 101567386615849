
import {  collection, getDocs, addDoc,getDoc, query, where, doc, limit, deleteDoc, updateDoc, orderBy, startAfter, startAt, endBefore } from 'firebase/firestore/lite';
import { db } from "../../utils/firebase";
const postsCol = collection(db, 'posts');

// Get a list of cities from your database
export async function getAllPosts() {
  
  const postSnapshot = await getDocs(postsCol);
  const postList = postSnapshot.docs.map(doc => doc.data());
  return postList;
}

export async function getTrendingPosts() {
  let q = query(postsCol, where("type","array-contains","trending"));
  const postSnapshot = await getDocs(q);
  const postList = postSnapshot.docs.map(doc => doc.data());
  return postList;
}

export async function getPostsByTag(tag) {
  let q = query(postsCol, where("tags","array-contains",tag));
  const postSnapshot = await getDocs(q);
  const postList = postSnapshot.docs.map(doc => doc.data());
  return postList;
}

export async function getPostsByType(type) {
  let q = query(postsCol, where("type","array-contains",type));
  const postSnapshot = await getDocs(q);
  const postList = postSnapshot.docs.map(doc => doc.data());
  return postList;
}


export async function getPostsByCategory(category) {
  let q = query(postsCol, where("category","==",category));
  const postSnapshot = await getDocs(q);
  const postList = postSnapshot.docs.map(doc => doc.data());
  return postList;
}


export async function createPost(values){
  const postRef = await addDoc(postsCol, values);
  return postRef;
}

export async function updatePost(id, values){
  let ref = doc(db,'posts',id)
  const postRef = await updateDoc(ref, values);
  return postRef;
}

export async function getSinglePost(id){
  
  let ref = doc(db,'posts',id)
  const postRef = await getDoc(ref);
  return postRef.data();
}

export async function onDeletePost(id){
  let ref = doc(db,'posts',id)
  const postRef = await deleteDoc(ref);
  return postRef.data();
}

export async function getPostBySlug(slug){
  if(slug){

    let q = query(postsCol, where("slug","==",slug), limit(1));
    const postRef = await getDocs(q);
    
    if(postRef && postRef.docs[0]){
      return {id:postRef.docs[0]?.id, ...postRef.docs[0].data()}
    }else{
      return null;
    }
  }
  }

  export async function getPreviousPost(id){
    let ref = doc(db,'posts',id)
  const postRef = await getDoc(ref);
 

  const q =  query(collection(db, "posts"),
  orderBy("date","desc"),
  startAfter(postRef),
  limit(1));
  const previous = await getDocs(q);
    return previous.docs[0].data();
  }

  export async function getNextPost(id){
    let ref = doc(db,'posts',id);
    const postRef = await getDoc(ref);
   
    const q =   query(collection(db, "posts"),
    orderBy("date","asc"),
    startAfter(postRef),
    limit(1));

    const next = await getDocs(q);
    return next.docs[0].data();
  }
