import React from 'react'
import {
    MDXEditor,
    headingsPlugin,
    imagePlugin,
    InsertImage,
    toolbarPlugin,
    UndoRedo,
    BoldItalicUnderlineToggles,
    listsPlugin,
    quotePlugin,
    linkPlugin,
    linkDialogPlugin,
    thematicBreakPlugin,
    BlockTypeSelect,
    CreateLink,
    InsertTable,
    Separator
  } from "@mdxeditor/editor";
  import "@mdxeditor/editor/style.css";
import { getStorage, ref, uploadBytes } from 'firebase/storage';

  
  

const AppEditor = ({markdown,onChange }) => {
const storage = getStorage();
  async function imageUploadHandler(image) {
    const storageRef = ref(storage, '')
    uploadBytes(storageRef, image).then((snapshot) => {
      console.log('Uploaded a blob or file!');
      console.log(snapshot);
    });
    // send the file to your server and return
    // the URL of the uploaded image in the response

    // const json = (await response.json())
    // return json.url
  }

  return (
    <MDXEditor
                contentEditableClassName="editor"
                onChange={onChange}
                markdown={markdown}
                plugins={[
                  imagePlugin({
                    imageUploadHandler: imageUploadHandler
                  }),
                  headingsPlugin(),
                  listsPlugin(),
                  linkPlugin(),
                  linkDialogPlugin(),
                  quotePlugin(),
                  thematicBreakPlugin(),
                  toolbarPlugin({
                    toolbarContents: () => (
                      <>
                        <UndoRedo />
                        <Separator />
                        <BoldItalicUnderlineToggles />
                        <Separator />
                        <InsertImage />
                        <BlockTypeSelect />
                        <CreateLink />
                        <InsertTable />
                      </>
                    ),
                  }),
                ]}
              />
  )
}

export default AppEditor