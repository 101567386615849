import React, { useRef, useState, useEffect } from 'react'
import '@mdxeditor/editor/style.css'
import { Input, Form, Row, Col, Select, DatePicker, Button, Space, message, Card } from 'antd'
import { createPost, getAllPosts, getSinglePost, updatePost } from '../../store/actions/postAction'
import {  getAllTags, getAllCategories } from '../../store/actions/tagAction'

import dayjs from 'dayjs';
import { auth, provider } from '../../utils/firebase'
import FontAwesome from '../../components/uiStyle/FontAwesome'
import { Link, useParams } from 'react-router-dom'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import AppEditor from './AppEditor';
import { getStorage, ref } from "firebase/storage";

  // construct a ref to the editor
 
function MyAccount({user}) {
  const [form] = Form.useForm();
  const {id} = useParams();

  const [editorValue, setEditorValue] = useState("");
  const [loading, setLoading] = useState(true);

  const [selected, setSelected] = useState();


  const [types, setTags] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let getTags = async ()=>{
      let data = await getAllTags();
      setTags(data);
      let cats = await getAllCategories();
      setCategories(cats);
    }

    getTags();
  }, [])


  useEffect(()=>{
    if(id){
      console.log(id);
      getSinglePost(id).then(response=>{
        setSelected(response);
        setLoading(false);
        form.setFieldsValue({...response, date:dayjs(response.date)})
        setEditorValue(response.body);
      }).catch(err=>{
        setLoading(false);
        console.log(err);
      })
    }else{
      setLoading(false);
    }
  },[id])
  
  const submitPost = (values)=>{
    if(selected){
      updatePost(id, {...values, body:editorValue, date:values.date.toISOString()}).then(response=>{
        form.resetFields();
        setEditorValue("")
        message.success({content:"Post has been updated successfully !"});
      }).catch(err=>{
        console.log(err);
        message.error("Error in updating post !");
      })
    }else{
      createPost({...values, body:editorValue, date:values.date.toISOString()}).then(response=>{
        form.resetFields();
        setEditorValue("")
        message.success({content:"Post has been created successfully !"});
      }).catch(err=>{
        message.error("Error in creating post !");
      })
    }
    
  }
    const ref = useRef();

    const onChangeEditor = (e) => {
      setEditorValue(e);
    };

    
  return (
    
  <>
  <Card>
    <Row className='container' style={{marginTop:32}} justify="space-between">
      <Col><Link to="/">Back to Home</Link></Col>
      <Col>{user?.email}</Col>
    </Row>
</Card>
  <Row style={{marginTop:32}} justify="center" gutter={[16,16]}>
    <Col lg={12} span={24}>
      <h3>Create a New Post</h3>
      <Form form={form} layout='vertical' onFinish={submitPost}>
      <Row><Form.Item name="date" label="Date" rules={[{required:true}]}>
          <DatePicker size='small' />
        </Form.Item></Row>
        <Form.Item name="title" label="Title" rules={[{required:true}]}>
          <Input />
        </Form.Item>
        <Form.Item name="slug" label="Slug" rules={[{required:true}]}>
          <Input />
        </Form.Item>
        <Form.Item name="author" label="Author" rules={[{required:true}]}>
          <Input />
        </Form.Item>
        
        <Row gutter={[8,8]}>
          <Col lg={8} md={12} span={24}>
        
          <Form.Item name="category" label="Category" rules={[{required:true}]}>
          <Select options={categories} />
        </Form.Item>
        </Col>
        <Col lg={8} md={12} span={24}>
        <Form.Item name="tags" label="Tags" rules={[{required:true}]}>
          <Select  mode='tags'   />
        </Form.Item>
        
        </Col>
        <Col lg={8} md={12} span={24}>
        <Form.Item name="type" label="Type">
          <Select options={types} mode='tags'   />
        </Form.Item>
        
        </Col>

        </Row>

        <Form.Item name="image" label="Image" rules={[{required:true}]}>
          <Input />
        </Form.Item>


     
     
    <Form.Item name="body">
    {loading?'Loading...':( selected && editorValue) ? <AppEditor markdown={editorValue} onChange={onChangeEditor} />:<AppEditor markdown={editorValue} onChange={onChangeEditor} />}
        </Form.Item>
        <Form.Item>
         <Button htmlType='submit' block type="primary">Submit Post</Button>
         </Form.Item>

         </Form>
         </Col>

       
      </Row>
      
    
    </>
  )
  
}

export default MyAccount;