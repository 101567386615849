import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";

import { ImMobile } from "react-icons/im";
import { BsSpeaker, BsEnvelopeOpenHeartFill } from "react-icons/bs";
import { getAllPosts } from '../../store/actions/postAction';


const FooterMoreNews = () => {

    const [news, setNews] = useState([]);
        useEffect(() => {
        const getPost = async ()=>{
            const posts = await getAllPosts();
            setNews(posts);
        }
        getPost();
        
        
    
       }, [])
    return (
        <div className="extra_newss border_white_left pl-4">
            <h3 className="widget-title2">More news</h3>
            {news.slice(0,6).map((item, i) => (
                <div key={i} className="single_extra_news border_white_bottom">
                    <p>{item.category} <span> / {item.date}</span></p>
                    <Link to={`/${item?.slug}`}>{item.title}</Link>
                    <span className="news_counter">{i + 1}</span>
                </div>
            ))}
            <div className="space-40"/>
            <div className="border_white_bottom"/>
            <div className="space-40"/>
            <div className="footer_contact">
                <h3 className="widget-title2">We are available</h3>
                <div className="single_fcontact">
                    <div className="fcicon">
                    <ImMobile size={20} color='#fff' />
                    </div>
                    <Link to="/">On your mobile</Link>
                </div>
                <div className="single_fcontact">
                    <div className="fcicon">
                    <BsSpeaker  size={20} color='#fff' />
                    </div>
                    <Link to="/">On smart speakers</Link>
                </div>
                <div className="single_fcontact">
                    <div className="fcicon">
                    <BsEnvelopeOpenHeartFill  size={20} color='#fff' />
                    </div>
                    <Link to="/">NewsLetter</Link>
                </div>
            </div>
        </div>
    );
};

export default FooterMoreNews;