import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";

import union from '../../doc/img/icon/union.png';
import category1 from '../../doc/img/categories/category1.jpg';
import category2 from '../../doc/img/categories/category2.jpg';
import category3 from '../../doc/img/categories/category3.jpg';
import category4 from '../../doc/img/categories/category4.jpg';
import category5 from '../../doc/img/categories/category5.jpg';
import category6 from '../../doc/img/categories/category6.jpg';
import { getAllCategories } from '../../store/actions/tagAction';



const CategoriesWidget = () => {
    let images = [category1,category2,category3,category4,category5,category6];
    const [categories, setCategories] = useState([]);
    useEffect(() => {
      let getData = async ()=>{
        let data = await getAllCategories();
        setCategories(data);
      }
      getData();
    }, [])
    
    return (
        <div className="widget category mb30">
            <div className="row">
                <div className="col-6 align-self-center">
                    <h2 className="widget-title">Categories</h2>
                </div>
                <div className="col-6 text-right align-self-center">
                    <Link to="/" className="see_all mb20">See All</Link>
                </div>
            </div>
            <ul>
                {categories.map((item, i) => (
                    <li key={i}>
                        <Link to="/" style={{background: `url(${images[i]})`}}> <span>{item.label}</span>
                            <img src={images[i]} alt="category"/>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CategoriesWidget;