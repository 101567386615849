import React, { useEffect, useRef, useState } from 'react';

// image
import {Link} from "react-router-dom";
import { getPostsByType } from '../../store/actions/postAction';
import Swiper from 'react-id-swiper';





const OurBlogSection = ({dark}) => {


    const swiperRef = useRef();

    const [latest, setLatest] = useState([]);

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };
    

useEffect(() => {
 const getLatest = async()=>{
    let posts = await getPostsByType('popular');
    setLatest(posts);
 }

 getLatest();
}, [])

const params = {
    slidesPerView: 4,
    spaceBetween: 30,
    loop: true,
    breakpoints: {
        1024: {
            slidesPerView: 4,
            spaceBetween: 30
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 20
        },
        320: {
            slidesPerView: 1,
            spaceBetween: 0
        }
    }
};
    return (
        <div className={`${dark ? 'primay_bg' : 'fourth_bg'} padding6030`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="heading">
                            <h2 className="widget-title">Our Latest Blog</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                <Swiper ref={swiperRef} {...params}>
                    {latest.map((item, i) => (
                        <div key={i} className="col-md-6 col-lg-4">
                            <div className="single_post post_type3 mb30">
                                <div className="post_img">
                                    <Link to="/">
                                        <img height={200} src={item?.image} alt="thumb"/>
                                    </Link>
                                </div>
                                <div className="single_post_text">
                                    <div className="meta3">
                                        <Link to="/">{item.category}</Link>
                                        <Link to="/">{item.date}</Link>
                                    </div>
                                    <h4><Link to={`/${item?.slug}`}>{item.title}</Link></h4>
                                    <div className="space-10"/>
                                    <p className="post-p">{item.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default OurBlogSection;