import React, {Fragment, useEffect, useState} from 'react';
import FontAwesome from "../uiStyle/FontAwesome";
import {Link} from "react-router-dom";

// images
import trendbig1 from '../../doc/img/trending/trendbig1.jpg';
import transm4 from '../../doc/img/trending/transm4.jpg';
import transm5 from '../../doc/img/trending/transm5.jpg';
import transm6 from '../../doc/img/trending/transm6.jpg';
import { getPostsByType } from '../../store/actions/postAction';
import useFormat from '../../utils/useFormat';

const posts = [
    {
        photo: transm4,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'Nancy Zhang a Chinese busy woman and Dhaka',
    },
    {
        photo: transm5,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'U.S. Response subash says he will label regions by risk of…',
    },
    {
        photo: transm6,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'Venezuela elan govt and opposit the property collect',
    },
];

const WidgetTrendingNews = ({dark}) => {

    const {formatDate} = useFormat();
    const [trendingNews, setTrendingNews] = useState([]);
    const [top, setTop] = useState();
    useEffect(() => {
    const getData = async()=>{
        let data = await getPostsByType("trending");
        setTrendingNews(data);
        setTop(data[data?.length-1]);
    }
    getData();
    }, [])

    return (
        <div className="trending_widget mb30">
            <h2 className="widget-title">Trending News</h2>
            <div className="single_post post_type3">
                <div className="post_img">
                    <div className="img_wrap">
                        <img src={top?.image} alt="trendbig1"/>
                    </div>
                    <span className="tranding"><FontAwesome name="bolt"/></span>
                </div>
                <div className="single_post_text">
                    <div className="meta3"><Link to="/">{top?.category}</Link>
                        <Link to="/">{formatDate(top?.date)}</Link>
                    </div>
                    <h4><Link to={`/${top?.slug}`}>{top?.title}</Link></h4>
                    <div className="space-10"/>
                    <p className="post-p">{top?.description}</p>
                </div>
            </div>

            {trendingNews.map((item, i) => (

                i<(trendingNews.length-1) && <Fragment key={i}>
                    <div className="space-15"/>
                    {dark ? <div className="border_white"/> : <div className="border_black"/>}
                    <div className="space-30"/>
                    <div className="single_post widgets_small">
                        <div className="post_img">
                            <div className="img_wrap">
                                <img src={item?.image} alt="thumb"/>
                            </div>
                            <span className="tranding"><FontAwesome name="bolt"/></span>
                        </div>
                        <div className="single_post_text">
                            <div className="meta2"><Link to="/">{item.category}</Link>
                                <Link to="/">{formatDate(item.date)}</Link>
                            </div>
                            <h4><Link to={`/${item?.slug}`}>{item.title}</Link></h4>
                        </div>
                    </div>
                </Fragment>
            ))}
        </div>
    );
};

export default WidgetTrendingNews;